export const VALIDATE = {
  noSpace: /^\S+$/,
  hour: /(\d){2}:(\d){2}/,
  number: /[0-9]/,
  textUppercase: /[a-z]/,
  textNoUppercase: /[A-Z]/,
  dateRegex: /^[A-Za-z]{3}, \d{2} [A-Za-z]{3} \d{4} \d{2}:\d{2}:\d{2} GMT$/,
  phoneRegex: /^(?:\+84|84)?(0[3-9][0-9]{8}|02(96|54|09|04|91|22|75)[0-9]{7})$/,
  cccdRegex: /^\d{12}$/,
  passwordRegex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
  nameRegex:
    /^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơĂĨŨƯƠàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờởỡợùúủũụưừứửữựỳýỷỹỵỸý]+( [a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơĂĨŨƯƠàáảãạâầấẩẫậăằắẳẵặèéẻẽẹêềếểễệìíỉĩịòóỏõọôồốổỗộơờởỡợùúủũụưừứửữựỳýỷỹỵỸý]+)+$/
}
